<template>
  <kn-table 
    :headers="headers" 
    :items="items"
    :loading="loading"
    :fullWidth="true"
    tableTitle="Inventario" 
    newButtonLabel="Agregar inventario" 
  />
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import KnTable from '@/components/tables/KnTable.vue'
import { apiMixin } from '@/mixins/apiMixin'
export default {
  components: { KnTable },
  mixins: [apiMixin],
  data() {
    return {
      headers: [
        { text: 'Producto', value: 'producto.nombre_producto', class: 'purple--text' },
        { text: 'Opcion', value: 'opcion_producto.nombre_opcion', class: 'purple--text' },
        { text: 'Unidad', value: 'unidad_medida.dato', class: 'purple--text' },
        { text: 'Disponible', value: 'cantidad_disponible', class: 'purple--text' },
        { text: 'ID', value: 'id', class: 'purple--text' },
        { text: '', value: 'acciones' },
      ],
      items: [],
      search: null,
      loading: true
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData', 'warehouse'])
  },
  async created() {
    this.setIsLogin(false)
    this.loading = true
    this.items = await this.fetchResultsByEI('inventarios', 'inventario', this.institutionId)
    this.loading = false
  },
  methods: {
    ...mapMutations(['setIsLogin'])
  }
}
</script>
